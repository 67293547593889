import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['search', 'button'];

  onChange() {
    this.searchTarget.value.trim().length > 0
      ? this.buttonTarget.classList.remove('rp-btn-disabled')
      : this.buttonTarget.classList.add('rp-btn-disabled');
  }

  handleEnter(event) {
    if (event.key === 'Enter') {
      this.searchTarget.value.trim().length == 0 && event.preventDefault();
    }
  }
}
