import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['input', 'hiddenInput', 'dropdown', 'option', 'warning'];
  static values = {
    url: String,
    fieldName: String,
  };

  connect() {
    this.value = null;
    this.selected = false;
    if (!this.urlValue) this.inputTarget.setAttribute('readonly', true);
  }

  getDeviceIcon(deviceType) {
    switch (deviceType) {
      case 'gateway':
        return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <rect width="20" height="8" x="2" y="14" rx="2" />
                  <path d="M6.01 18H6" />
                  <path d="M10.01 18H10" />
                  <path d="M15 10v4" />
                  <path d="M17.84 7.17a4 4 0 0 0-5.66 0" />
                  <path d="M20.66 4.34a8 8 0 0 0-11.31 0" />
                </svg>`;
      case 'ble':
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M14.3116 12L18.6548 16.3431L12.9979 22H10.9979V15.3137L6.63394 19.6777L5.21973 18.2635L10.9979 12.4853V11.5147L5.21973 5.73654L6.63394 4.32233L10.9979 8.68629V2H12.9979L18.6548 7.65685L14.3116 12ZM12.9979 13.5147V19.1716L15.8263 16.3431L12.9979 13.5147ZM12.9979 10.4853L15.8263 7.65685L12.9979 4.82843V10.4853Z"></path></svg>`;
      case 'mobile_phone':
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M7 4V20H17V4H7ZM6 2H18C18.5523 2 19 2.44772 19 3V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V3C5 2.44772 5.44772 2 6 2ZM12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17Z"></path></svg>`;
      case 'cellular':
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M18.364 18.3641C21.8787 14.8493 21.8787 9.15086 18.364 5.63614L19.7782 4.22192C24.0739 8.51769 24.0739 15.4825 19.7782 19.7783L18.364 18.3641ZM5.63604 5.63614C2.12132 9.15086 2.12132 14.8493 5.63604 18.3641L4.22183 19.7783C-0.0739417 15.4825 -0.0739417 8.51769 4.22183 4.22192L5.63604 5.63614ZM15.5355 15.5355C17.4882 13.5829 17.4882 10.4171 15.5355 8.46445L16.9497 7.05024C19.6834 9.78391 19.6834 14.2161 16.9497 16.9497L15.5355 15.5355ZM8.46447 8.46445C6.51184 10.4171 6.51184 13.5829 8.46447 15.5355L7.05025 16.9497C4.31658 14.2161 4.31658 9.78391 7.05025 7.05024L8.46447 8.46445ZM12 14.0001C13.1046 14.0001 14 13.1046 14 12.0001C14 10.8955 13.1046 10.0001 12 10.0001C10.8954 10.0001 10 10.8955 10 12.0001C10 13.1046 10.8954 14.0001 12 14.0001Z"></path></svg>`;
      default:
        return ``;
    }
  }

  toggleDropdown() {
    this.element.classList.toggle('active');
    this.dropdownTarget.classList.toggle('active');
    if (this.urlValue) {
      if (this.inputTarget.value && this.hiddenInputTarget.value) {
        this.value = this.inputTarget.value;
        this.inputTarget.placeholder = this.value;
        this.inputTarget.value = '';
        this.inputTarget.dispatchEvent(new Event('select'));
      }
      this.fetchOptions();
    }
  }

  searchOptions() {
    if (!this.hiddenInputTarget.value || (this.hiddenInputTarget.value && this.inputTarget.value)) {
      this.fetchOptions();
    }
  }

  async fetchOptions() {
    const response = await fetch(`${this.urlValue}&search=${this.inputTarget.value}`, {
      headers: {
        Accept: 'application/json',
      },
    });
    const data = await response.json();
    this.dropdownTarget.innerHTML = '';
    if (data.length > 0) {
      data.forEach((option) => {
        const { label, value, message } = this.getOptionDataBasedOnType(option);
        const optionElement = document.createElement('div');
        optionElement.innerHTML = `
          <div
            class="rp-select-option flex gap-2"
            data-value="${value}"
            data-message="${message}"
            data-rp-select-target="option"
            data-rp-template-scope-target="templateScopeSelect"
            data-action="
              click->rp-select#selectOption
              click->rp-template-scope#toggleDropdowns
            "
          >${label}</div>
        `;
        this.dropdownTarget.append(optionElement);
      });
    } else {
      this.setEmptyState(this.dropdownTarget);
    }
  }

  getOptionDataBasedOnType(option) {
    let label;
    let value;
    let message;
    if (this.fieldNameValue == 'Device ID') {
      label = `<div class="flex gap-2 items-center">
                  <span class="w-4 h-4 text-primary" id="rp_option_icon">
                    ${this.getDeviceIcon(option.device_type)}
                  </span>
                  <span id="rp_option_value">
                    ${option.device_identifier}
                  </span>
                </div>`;
      value = option.device_identifier;
      message = this.getTrackerHealthMessage(option);
    }
    return { label, value, message };
  }

  setEmptyState(element) {
    element.innerHTML = `
      <div class="w-full flex items-center flex-wrap justify-center mt-10 mb-10">
        <div class="grid w-60">
            <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="70" height="65" viewBox="0 0 128 124" fill="none">
              <path d="M110.158 58.4715H110.658V57.9715V36.9888C110.658 32.749 107.226 29.317 102.986 29.317H51.9419C49.6719 29.317 47.5643 28.165 46.3435 26.2531L46.342 26.2509L43.7409 22.2253L43.7404 22.2246C42.3233 20.0394 39.8991 18.7142 37.2887 18.7142H20.8147C16.5749 18.7142 13.1429 22.1462 13.1429 26.386V57.9715V58.4715H13.6429H110.158Z" fill="#f5f7fa" stroke="#E5E7EB" />
              <circle cx="1.07143" cy="1.07143" r="1.07143" transform="matrix(-1 0 0 1 36.1429 23.5)" fill="#E5E7EB" />
              <circle cx="1.07143" cy="1.07143" r="1.07143" transform="matrix(-1 0 0 1 29.7144 23.5)" fill="#E5E7EB" />
              <circle cx="1.07143" cy="1.07143" r="1.07143" transform="matrix(-1 0 0 1 23.2858 23.5)" fill="#E5E7EB" />
              <path d="M112.363 95.459L112.362 95.4601C111.119 100.551 106.571 104.14 101.323 104.14H21.8766C16.6416 104.14 12.0808 100.551 10.8498 95.4592C10.8497 95.4591 10.8497 95.459 10.8497 95.459L1.65901 57.507L1.65896 57.5068C0.0470794 50.8383 5.09094 44.4286 11.9426 44.4286H111.257C118.108 44.4286 123.166 50.8371 121.541 57.5069L112.363 95.459ZM112.363 95.459L121.541 57.5077L112.363 95.459Z" fill="white" stroke="#E5E7EB" />
              <defs>
                  <filter id="filter0_d_14133_718" x="2" y="0" width="124" height="124" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14133_718" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14133_718" result="shape" />
                  </filter>
              </defs>
            </svg>
            <div>
              <h2 class="text-center text-gray-400 text-sm leading-relaxed pb-1">No Data</h2>
            </div>
        </div>
      </div>
    `;
  }

  onChange(event) {
    this.element.submit();
  }

  selectOption(event) {
    const selectedOption = event.currentTarget;
    this.addWarningMessage(selectedOption);

    this.inputTarget.value =
      this.fieldNameValue === 'Device ID'
        ? selectedOption.querySelector('#rp_option_value').textContent.trim()
        : selectedOption.textContent;

    this.inputTarget.dispatchEvent(new Event('select'));

    this.hiddenInputTarget.value = selectedOption.dataset.value;

    this.hideDropdown();
    this.optionTargets.forEach((opt) => opt.classList.remove('rp-selected'));
    selectedOption.classList.add('rp-selected');

    this.selected = true;
  }

  hideDropdown() {
    this.element.classList.remove('active');
    this.dropdownTarget.classList.remove('active');
  }

  addWarningMessage(selectedOption) {
    this.warningTarget.textContent = selectedOption.dataset.message;
    selectedOption.dataset.message
      ? this.warningTarget.classList.remove('hidden')
      : this.warningTarget.classList.add('hidden');
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      if (this.value && !this.inputTarget.value) {
        this.inputTarget.value = this.value;
        this.inputTarget.placeholder = 'Select Device Identifier';
        this.inputTarget.dispatchEvent(new Event('select'));
      }
      this.close();
    }
  }

  close() {
    this.hideDropdown();
    if (!this.hiddenInputTarget.value) {
      this.inputTarget.value = '';
    }
  }

  getTrackerHealthMessage(data) {
    let message;
    if (Object.keys(data.recent_reading).length == 0) {
      message = 'Tracker has not reported in the last 60 mins';
    } else {
      let timestamp = data.recent_reading.timestamp;
      let now = new Date().getTime();
      let differenceInMinutes = (now - timestamp) / (1000 * 60);
      if (differenceInMinutes > 60) {
        message = 'Tracker has not reported in the last 60 mins';
      } else {
        message = '';
      }
    }

    return message;
  }

  disconnect() {
    this.close();
  }
}
