import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputContainer', 'inputTemplate', 'warningMessage', 'addInputButton'];

  connect() {
    this.updateAddButton();
  }

  addInput(event) {
    event.preventDefault();
    if (!this.allFieldsValid()) {
      this.showValidationWarning();
      return;
    }

    // Check if the maximum limit (3 fields) is reached
    if (this.inputContainerTarget.children.length >= 3) {
      this.showMaxLimitWarning();
      return;
    }

    const template = this.inputTemplateTarget.content.cloneNode(true);
    this.inputContainerTarget.appendChild(template);

    this.hideWarningMessages();

    this.updateAddButton();
  }

  removeInput(event) {
    event.preventDefault();

    event.currentTarget.closest("[data-rp-template-hierarchy-target='inputWrapper']").remove();
    this.hideWarningMessages();
    if (this.inputContainerTarget.children.length === 0) {
      this.addInput();
    }
    this.updateAddButton();
  }

  allFieldsValid() {
    const inputs = this.inputContainerTarget.querySelectorAll("[name='template[hierarchy][]']");
    for (const input of inputs) {
      if (!input.value.trim()) {
        input.classList.add('rp-input-error');
        return false;
      } else {
        input.classList.remove('rp-input-error');
      }
    }
    return true;
  }

  updateAddButton() {
    const wrappers = this.inputContainerTarget.querySelectorAll(
      "[data-rp-template-hierarchy-target='inputWrapper']",
    );

    wrappers.forEach((wrapper, index) => {
      const addButton = wrapper.querySelector('.add-input-button');
      addButton.style.display = index === wrappers.length - 1 ? 'block' : 'none';
    });

    wrappers.forEach((wrapper) => {
      const removeButton = wrapper.querySelector('.remove-input-button');
      if (removeButton) {
        removeButton.style.display = 'block';
      }
    });

    const addButton = this.addInputButtonTarget;
    if (this.inputContainerTarget.children.length >= 3) {
      addButton.style.display = 'none';
    } else {
      addButton.style.display = 'block';
    }
  }

  showMaxLimitWarning() {
    this.warningMessageTarget.textContent = 'A maximum of 3 fields are allowed.';
    this.warningMessageTarget.style.display = 'block';
  }

  showValidationWarning() {
    this.warningMessageTarget.textContent = 'Please fill in all fields before adding a new one.';
    this.warningMessageTarget.style.display = 'block';
  }

  hideWarningMessages() {
    this.warningMessageTarget.style.display = 'none';
  }
}
