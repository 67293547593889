import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'hiddenInput', 'dropdown', 'option', 'templateScopeSelect'];
  static values = {
    type: String,
  };
  connect() {
    this.selected = false;
    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  }

  toggleDropdown(event) {
    this.element.classList.toggle('active');
    this.dropdownTarget.classList.toggle('active');
    if (this.inputTarget.value && this.hiddenInputTarget.value) {
      this.value = this.inputTarget.value;
      this.inputTarget.placeholder = this.value;
      this.inputTarget.value = '';
    }
    this.setEmptyState(this.dropdownTarget);
  }

  searchOptions(event) {
    const isActive = this.dropdownTarget.classList.contains('active');
    if (!isActive) {
      this.dropdownTarget.classList.add('active');
    }
    if (this.inputTarget.value.length > 3) {
      if (!this.autocompleteService) {
        console.warn('Google Places API not yet loaded');
        return;
      }
      this.autocompleteService.getPlacePredictions(
        { input: this.inputTarget.value },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            this.dropdownTarget.innerHTML = '';
            predictions.forEach((prediction) => {
              const optionElement = document.createElement('div');
              optionElement.innerHTML = `
                <div
                  class="rp-select-option"
                  data-value="${prediction.place_id}"
                  data-rp-address-autocomplete-target="option"
                  data-action="
                    click->rp-address-autocomplete#selectOption
                  "
                >${prediction.description}</div>
              `;
              this.dropdownTarget.append(optionElement);
            });
          } else {
            this.setEmptyState(this.dropdownTarget);
          }
        },
      );
    }
  }

  setEmptyState(element) {
    element.innerHTML = `
      <div class="w-full flex items-center flex-wrap justify-center mt-10 mb-10">
        <div class="grid w-60">
            <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="70" height="65" viewBox="0 0 128 124" fill="none">
              <path d="M110.158 58.4715H110.658V57.9715V36.9888C110.658 32.749 107.226 29.317 102.986 29.317H51.9419C49.6719 29.317 47.5643 28.165 46.3435 26.2531L46.342 26.2509L43.7409 22.2253L43.7404 22.2246C42.3233 20.0394 39.8991 18.7142 37.2887 18.7142H20.8147C16.5749 18.7142 13.1429 22.1462 13.1429 26.386V57.9715V58.4715H13.6429H110.158Z" fill="#f5f7fa" stroke="#E5E7EB" />
              <circle cx="1.07143" cy="1.07143" r="1.07143" transform="matrix(-1 0 0 1 36.1429 23.5)" fill="#E5E7EB" />
              <circle cx="1.07143" cy="1.07143" r="1.07143" transform="matrix(-1 0 0 1 29.7144 23.5)" fill="#E5E7EB" />
              <circle cx="1.07143" cy="1.07143" r="1.07143" transform="matrix(-1 0 0 1 23.2858 23.5)" fill="#E5E7EB" />
              <path d="M112.363 95.459L112.362 95.4601C111.119 100.551 106.571 104.14 101.323 104.14H21.8766C16.6416 104.14 12.0808 100.551 10.8498 95.4592C10.8497 95.4591 10.8497 95.459 10.8497 95.459L1.65901 57.507L1.65896 57.5068C0.0470794 50.8383 5.09094 44.4286 11.9426 44.4286H111.257C118.108 44.4286 123.166 50.8371 121.541 57.5069L112.363 95.459ZM112.363 95.459L121.541 57.5077L112.363 95.459Z" fill="white" stroke="#E5E7EB" />
              <defs>
                  <filter id="filter0_d_14133_718" x="2" y="0" width="124" height="124" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14133_718" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14133_718" result="shape" />
                  </filter>
              </defs>
            </svg>
            <div>
              <h2 class="text-center text-gray-400 text-sm leading-relaxed pb-1">No Data</h2>
            </div>
        </div>
      </div>
    `;
  }

  onChange(event) {
    this.element.submit();
  }

  selectOption(event) {
    const selectedOption = event.currentTarget;
    this.inputTarget.value = selectedOption.textContent;
    this.hiddenInputTarget.value = selectedOption.dataset.value;
    this.element.classList.remove('active');
    this.dropdownTarget.classList.remove('active');
    this.optionTargets.forEach((opt) => opt.classList.remove('rp-selected'));
    selectedOption.classList.add('rp-selected');
    this.selected = true;

    this.placesService.getDetails({ placeId: this.hiddenInputTarget.value }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = place.address_components;
        const location = place.geometry.location;
        const getAddressComponent = (type) => {
          const component = addressComponents.find((component) => component.types.includes(type));
          return component ? component.long_name : '';
        };

        const city = getAddressComponent('locality');
        const state = getAddressComponent('administrative_area_level_1');
        const country = getAddressComponent('country');
        const zipcode = getAddressComponent('postal_code');
        const line_one =
          (getAddressComponent('street_number') + ' ' + getAddressComponent('route')).trim() ||
          getAddressComponent('premise');
        const line_two = getAddressComponent('sublocality') || getAddressComponent('neighborhood');

        const placeDetails = {
          city,
          country,
          latitude: location.lat(),
          line_one,
          line_two,
          longitude: location.lng(),
          state,
          zipcode,
        };
        this.element.querySelector(`#${this.typeValue}_city`).querySelector('input').value =
          placeDetails.city;
        this.element.querySelector(`#${this.typeValue}_country`).querySelector('input').value =
          placeDetails.country;
        this.element.querySelector(`#${this.typeValue}_zip_code`).querySelector('input').value =
          placeDetails.zipcode;
        this.element.querySelector(`#${this.typeValue}_state`).querySelector('input').value =
          placeDetails.state;
        this.element.querySelector(`#${this.typeValue}_line_one`).querySelector('input').value =
          placeDetails.line_one;
        this.element.querySelector(`#${this.typeValue}_line_two`).querySelector('input').value =
          placeDetails.line_two;
        this.element.querySelector(`#${this.typeValue}_latitude`).querySelector('input').value =
          placeDetails.latitude;
        this.element.querySelector(`#${this.typeValue}_longitude`).querySelector('input').value =
          placeDetails.longitude;
      }
    });
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      if (this.value && !this.inputTarget.value) {
        this.inputTarget.value = this.value;
        this.inputTarget.placeholder = 'Select Address';
      }
      this.close();
    }
  }

  close() {
    this.element.classList.remove('active');
    this.dropdownTarget.classList.remove('active');
    if (!this.hiddenInputTarget.value) {
      this.inputTarget.value = '';
    }
  }

  disconnect() {
    this.close();
  }
}
