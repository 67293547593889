import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'templateScopeSelect',
    'profileFields',
    'deliveryOptionFields',
    'coldChainFields',
    'shipmentFields',
  ];

  connect() {
    const hiddenInput = document.getElementById('select_hidden_field');

    if (hiddenInput) {
      const selectedValue = hiddenInput.value;
      this.toggleDropdowns(selectedValue);
    }
  }

  toggleDropdowns(selectedValue = null) {
    const value =
      typeof selectedValue === 'string' ? selectedValue : this.templateScopeSelectTarget.value;

    if (value === 'Cold Chain' || value === 'cold_chain') {
      this.profileFieldsTarget.classList.remove('hidden');
      this.deliveryOptionFieldsTarget.classList.remove('hidden');
      this.shipmentFieldsTarget.classList.add('hidden');
    } else {
      this.profileFieldsTarget.classList.add('hidden');
      this.deliveryOptionFieldsTarget.classList.add('hidden');
      this.shipmentFieldsTarget.classList.remove('hidden');
    }
  }
}
