import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['manual', 'existingSite', 'saveSite', 'name'];
  static values = {
    type: String,
  };

  connect() {
    this.existingElements = this.existingSiteTarget.querySelectorAll('input', 'select');
    this.manualElements = this.manualTarget.querySelectorAll('input', 'select');
    this.nameElements = this.nameTarget.querySelectorAll('input');
    this.updateVisibility();
  }

  updateVisibility() {
    const addressType = this.typeValue;
    const manual = this.element.querySelector(`#${addressType}_manual`).checked;
    if (manual) {
      this.manualTarget.classList.remove('hidden');
      this.manualElements.forEach((el) => (el.disabled = false));
      this.existingSiteTarget.classList.add('hidden');
      this.existingElements.forEach((el) => (el.disabled = true));
    } else {
      this.manualTarget.classList.add('hidden');
      this.manualElements.forEach((el) => (el.disabled = true));
      this.existingSiteTarget.classList.remove('hidden');
      this.existingElements.forEach((el) => (el.disabled = false));
    }
    this.showSiteNameField();
  }

  radioChanged() {
    this.updateVisibility();
  }

  showSiteNameField() {
    const addressType = this.typeValue;
    const saveSiteName = this.element.querySelector(`#${addressType}_site_name`).checked;
    if (saveSiteName) {
      this.nameTarget.classList.contains('hidden') && this.nameTarget.classList.remove('hidden');
      this.nameElements.forEach((el) => (el.disabled = false));
    } else {
      !this.nameTarget.classList.contains('hidden') && this.nameTarget.classList.add('hidden');
      this.nameElements.forEach((el) => (el.disabled = true));
    }
  }
}
