// app/javascript/controllers/rp_color_preview_controller.js
import { Controller } from '@hotwired/stimulus';
import { generate } from '@ant-design/colors';

export default class extends Controller {
  static targets = ['previewLogo', 'previewDots', 'previewBars'];
  static values = {
    color: String,
  };

  connect() {
    if (this.colorValue) {
      this.updatePreview();
    }

    // Listen for color changes from the color picker
    document.addEventListener('color-picker:changed', this.handleColorChange.bind(this));
  }

  disconnect() {
    document.removeEventListener('color-picker:changed', this.handleColorChange.bind(this));
  }

  handleColorChange(event) {
    this.colorValue = event.detail.color;
    this.updatePreview();
  }

  colorValueChanged() {
    this.updatePreview();
  }

  updatePreview() {
    const colors = generate(this.colorValue);

    // Update logo with primary color
    if (this.hasPreviewLogoTarget) {
      this.previewLogoTarget.style.backgroundColor = this.colorValue;
    }

    // Update preview dots - 3 gray dots and 1 colored dot
    if (this.hasPreviewDotsTarget) {
      this.previewDotsTarget.innerHTML = `
        <button type="button" class="w-2 h-2 rounded-full bg-gray-300"></button>
        <button type="button" class="w-2 h-2 rounded-full bg-gray-300"></button>
        <button type="button" class="w-2 h-2 rounded-full bg-gray-300"></button>
        <button type="button" class="w-2 h-2 rounded-full" style="background-color: ${this.colorValue}"></button>
      `;
    }

    // Update preview bars
    if (this.hasPreviewBarsTarget) {
      this.previewBarsTarget.innerHTML = `
        <div class="h-12 rounded-lg" style="background-color: ${colors[1]}"></div>
        <div class="h-12 rounded-lg" style="background-color: ${colors[5]}"></div>
        <div class="h-12 rounded-lg" style="background-color: ${colors[6]}"></div>
      `;
    }
  }
}
