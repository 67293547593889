// app/javascript/controllers/rp_color_picker_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'dialog',
    'trigger',
    'gradient',
    'hueSlider',
    'preview',
    'hexInput',
    'hiddenInput',
    'recommendedColors',
  ];
  static values = {
    color: { type: String, default: '#117BC2' },
    hue: { type: Number, default: 210 },
    saturation: { type: Number, default: 100 },
    lightness: { type: Number, default: 50 },
  };

  connect() {
    this.initializeRecommendedColors();
    this.updatePreview();
    this.clickHandler = this.handleClickOutside.bind(this);
  }

  dialogShown() {
    this.setupGradientCanvas();
    this.setupHueSlider();
    // Add click listener when dialog opens
    document.addEventListener('click', this.clickHandler);
  }

  dialogHidden() {
    // Remove click listener when dialog closes
    document.removeEventListener('click', this.clickHandler);
  }

  handleClickOutside(event) {
    if (
      this.hasDialogTarget &&
      !this.dialogTarget.contains(event.target) &&
      !this.triggerTarget.contains(event.target)
    ) {
      this.closeDialog();
    }
  }

  toggleDialog(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.dialogTarget.classList.contains('hidden')) {
      this.openDialog();
    } else {
      this.closeDialog();
    }
  }

  openDialog() {
    this.dialogTarget.classList.remove('hidden');
    this.dialogShown();
  }

  closeDialog() {
    this.dialogTarget.classList.add('hidden');
    this.dialogHidden();
  }

  setupGradientCanvas() {
    const canvas = this.gradientTarget;
    const ctx = canvas.getContext('2d');

    // Clear previous content
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // White to color to black gradient
    const gradientWidth = canvas.width;
    const gradientHeight = canvas.height;

    // Create saturation gradient (white to pure color)
    const saturationGradient = ctx.createLinearGradient(0, 0, gradientWidth, 0);
    saturationGradient.addColorStop(0, '#FFFFFF');
    saturationGradient.addColorStop(1, `hsl(${this.hueValue}, 100%, 50%)`);

    // Fill with saturation gradient
    ctx.fillStyle = saturationGradient;
    ctx.fillRect(0, 0, gradientWidth, gradientHeight);

    // Create brightness gradient (transparent to black)
    const brightnessGradient = ctx.createLinearGradient(0, 0, 0, gradientHeight);
    brightnessGradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
    brightnessGradient.addColorStop(1, 'rgba(0, 0, 0, 1)');

    // Apply brightness gradient
    ctx.fillStyle = brightnessGradient;
    ctx.fillRect(0, 0, gradientWidth, gradientHeight);
  }

  setupHueSlider() {
    const canvas = this.hueSliderTarget;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    // Create hue gradient
    const gradient = ctx.createLinearGradient(0, 0, width, 0);
    const hueStops = [
      'rgb(255, 0, 0)', // Red
      'rgb(255, 255, 0)', // Yellow
      'rgb(0, 255, 0)', // Green
      'rgb(0, 255, 255)', // Cyan
      'rgb(0, 0, 255)', // Blue
      'rgb(255, 0, 255)', // Magenta
      'rgb(255, 0, 0)', // Red
    ];

    hueStops.forEach((color, index) => {
      gradient.addColorStop(index / (hueStops.length - 1), color);
    });

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);
  }

  updateFromGradient(event) {
    if (event.buttons !== 1 && event.type !== 'click') return; // Only handle left click or click event

    event.preventDefault();
    const rect = this.gradientTarget.getBoundingClientRect();
    const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
    const y = Math.max(0, Math.min(event.clientY - rect.top, rect.height));

    this.saturationValue = (x / rect.width) * 100;
    this.lightnessValue = 100 - (y / rect.height) * 100;

    this.updateColor();

    // Close dialog on click (not on drag)
    if (event.type === 'click') {
      this.closeDialog();
    }
  }

  updateFromHueSlider(event) {
    if (event.buttons !== 1 && event.type !== 'click') return; // Only handle left click or click event

    event.preventDefault();
    const rect = this.hueSliderTarget.getBoundingClientRect();
    const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));

    this.hueValue = (x / rect.width) * 360;
    this.setupGradientCanvas();
    this.updateColor();
  }

  updateFromHexInput(event) {
    event.preventDefault();
    const hex = event.target.value.toUpperCase();
    if (/^#[0-9A-F]{6}$/i.test(hex)) {
      this.colorValue = hex;
      this.updatePreview();
    }
  }

  updateColor() {
    const color = this.hslToHex(this.hueValue, this.saturationValue, this.lightnessValue);
    this.colorValue = color;
    this.updatePreview();
  }

  updatePreview() {
    const color = this.colorValue;

    if (this.hasHexInputTarget) {
      this.hexInputTarget.value = color.toUpperCase();
    }

    if (this.hasPreviewTarget) {
      this.previewTarget.style.backgroundColor = color;
    }

    if (this.hasTriggerTarget) {
      this.triggerTarget.style.backgroundColor = color;
    }

    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = color.toUpperCase();
    }

    // Dispatch a custom event that the preview controller can listen to
    const event = new CustomEvent('color-picker:changed', {
      detail: { color: color },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
  }

  initializeRecommendedColors() {
    const recommendedColors = [
      '#000000',
      '#424242',
      '#666666',
      '#808080',
      '#9E9E9E',
      '#BDBDBD',
      '#E0E0E0',
      '#EEEEEE',
      '#F5F5F5',
      '#FFFFFF',
      '#FF0000',
      '#FF9800',
      '#FFEB3B',
      '#8BC34A',
      '#4CAF50',
      '#009688',
      '#00BCD4',
      '#2196F3',
      '#673AB7',
      '#E91E63',
      '#FFE4E1',
      '#FFE0B2',
      '#FFF9C4',
      '#DCEDC8',
      '#C8E6C9',
      '#B2DFDB',
      '#B2EBF2',
      '#BBDEFB',
      '#D1C4E9',
      '#F8BBD0',
    ];

    if (this.hasRecommendedColorsTarget) {
      this.recommendedColorsTarget.innerHTML = recommendedColors
        .map(
          (color) => `
          <button type="button" 
                  class="color-swatch" 
                  style="background-color: ${color}"
                  data-action="click->rp-color-picker#selectRecommendedColor"
                  data-color="${color}">
          </button>
        `,
        )
        .join('');
    }
  }

  selectRecommendedColor(event) {
    event.preventDefault();
    const color = event.currentTarget.dataset.color;
    this.colorValue = color;
    this.updatePreview();
    this.closeDialog();
  }
}
