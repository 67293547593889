// app/javascript/controllers/rp_file_upload_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'preview', 'filename', 'displayContainer'];

  updateDisplay() {
    const file = this.inputTarget.files[0];
    if (file) {
      this.filenameTarget.textContent = file.name;
      this.displayContainerTarget.classList.remove('hidden');

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewTarget.innerHTML = `<img src="${e.target.result}" class="w-8 h-8 object-contain">`;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removePreview() {
    this.inputTarget.value = '';
    this.previewTarget.innerHTML = '';
    this.filenameTarget.textContent = '';
    this.displayContainerTarget.classList.add('hidden');
  }
}
