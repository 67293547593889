import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggle(e) {
    e.preventDefault();

    const li = e.target.closest('li');

    li.querySelector(':scope > div > a.rp-icon-caret-down').classList.toggle('!hidden');
    li.querySelector(':scope > div > a.rp-icon-caret-right').classList.toggle('!hidden');

    // childUL
    const childUL = li.querySelector(':scope > ul');
    if (childUL) {
      childUL.classList.toggle('!hidden');
    }

    this.highlightLevel1(e);
  }

  select(e) {
    this.element.querySelectorAll('.current').forEach((el) => {
      el.classList.remove('current');
    });

    e.target.closest('.rp-tree-item').classList.add('current');
    this.highlightLevel1(e);
  }

  highlightLevel1(e) {
    this.element.querySelectorAll('.current-level').forEach((el) => {
      el.classList.remove('current-level');
    });

    this.element.querySelectorAll(':scope > li').forEach((el) => {
      if (el.contains(e.target)) {
        el.classList.add('current-level');
      }
    });
  }

  // Clears all tree selections on page
  clearAllSelections(e) {
    document.querySelectorAll('.rp-tree-container .current').forEach((el) => {
      el.classList.remove('current');
    });
  }
}
