import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['field', 'error', 'submit'];

  connect() {
    this.shipment_identifier_regex = new RegExp(
      /^[a-zA-Z0-9](?!.*[-_/ ]$)[a-zA-Z0-9\-_/ ]*[a-zA-Z0-9]$/,
    );
  }

  validateForm(event) {
    this.errors = 0;
    this.fieldTargets.forEach((field) => this.validateEachField(field));
    if (this.errors > 0) event.preventDefault();
  }

  readyErrorTarget(errorTarget, field) {
    errorTarget.textContent = '';
    errorTarget.style.opacity = '0';
    errorTarget.classList.remove('animate-squeezeInFromTop');
    field.classList.remove('is-danger');
  }

  validateField(event) {
    const field = event.target;
    this.validateEachField(field);
  }

  validateEachField(field) {
    const errorTarget = this.getErrorTargetForField(field);
    const validations = JSON.parse(field.dataset.validations);
    const value = field.value;

    this.readyErrorTarget(errorTarget, field);
    validations.forEach((validation) => this.displayErrors(validation, errorTarget, value, field));
  }

  displayErrors(validation, errorTarget, value, field) {
    if (validation.required && !value.trim().length && !field.disabled) {
      errorTarget.textContent = validation.message;
      errorTarget.style.opacity = '100';
      field.classList.add('is-danger');
      errorTarget.classList.add('animate-squeezeInFromTop');
      this.errors += 1;
    }

    if (validation.regex && !this[validation.regex].test(value) && !field.disabled) {
      errorTarget.textContent = validation.message;
      errorTarget.style.opacity = '100';
      errorTarget.classList.add('animate-squeezeInFromTop');
      this.errors += 1;
    }
  }

  getErrorTargetForField(field) {
    const fieldIndex = this.fieldTargets.indexOf(field);
    return this.errorTargets[fieldIndex];
  }

  convertToUpperCase(event) {
    const input = event.target;
    input.value = input.value.toUpperCase();
  }
}
